<template>
    <div class="statistics-wrap">
        <el-card class="statistics-box-card">
            <div slot="header" class="box-card-header">
                <span class="title">数据概况</span>
                <div>
                    <el-radio-group size="medium" v-model="contentType" @input="handleSwitch">
                        <el-radio-button :label="0">全部</el-radio-button>
                        <el-radio-button :label="1">专业知识</el-radio-button>
                        <el-radio-button :label="2">业务知识</el-radio-button>
                        <el-radio-button :label="3">其他</el-radio-button>
                    </el-radio-group>
                    <el-radio-group size="medium" v-model="dateType" style="margin: 0 20px;" @input="handleSwitch">
                        <el-radio-button :label="1">今日</el-radio-button>
                        <el-radio-button :label="2">本周</el-radio-button>
                        <el-radio-button :label="3">本月</el-radio-button>
                        <el-radio-button :label="4">本年</el-radio-button>
                    </el-radio-group>
                    <el-date-picker v-model="date" value-format="yyyy-MM-dd" type="daterange" @change="handleSwitch"
                        :picker-options="pickerOptions" range-separator="-" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </div>
            </div>
            <div class="box-card-content">
                <div class="item">
                    <div class="number" style="color: #FF6000">{{ statisticsData.answerCount }}</div>
                    <div class="text">提问</div>
                </div>
                <div class="item">
                    <div class="number" style="color: #16BA10">{{ statisticsData.likedCount }}</div>
                    <div class="text">点赞</div>
                </div>
                <div class="item">
                    <div class="number" style="color: #1890FF">{{ statisticsData.feedbackCount }}</div>
                    <div class="text">反馈</div>
                </div>
                <div class="item">
                    <div class="number" style="color: #6486D0">{{ statisticsData.sharedCount }}</div>
                    <div class="text">分享</div>
                </div>
            </div>
        </el-card>
        <el-card class="box-card" style="margin-top: 20px;">
            <div v-loading="chartLoading">
                <div class="chart-date">
                    <el-date-picker v-model="chartDate" value-format="yyyy-MM-dd" type="daterange"
                        @change="handleChartSwitch" range-separator="-" :picker-options="pickerOptions"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <div v-if="showChart" id="myChart" :style="{ height: '500px' }" />
                <el-result v-else icon="info" subTitle="暂无数据" />
            </div>
        </el-card>
    </div>
</template>

<script>
import {
    messageStatistics,
    messageEcharts
} from "../../api/api";
import dayjs from 'dayjs';
export default {
    data() {
        return {
            contentType: 0,
            dateType: 1,
            date: null,
            chartDate: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
            statisticsData: {
                answerCount: 0,
                likedCount: 0,
                feedbackCount: 0,
                sharedCount: 0
            },
            showChart: true,
            chartLoading: false,
            pickerOptions: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now()
                }
            }
        }
    },
    methods: {
        drawLine(dateRange, chartData) {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("myChart"));
            // 绘制图表
            myChart.setOption({
                title: {
                    text: '聊天次数'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['业务知识', '专业知识', '其他']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: dateRange
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '业务知识',
                        type: 'line',
                        data: chartData[0].conversationCount
                    },
                    {
                        name: '专业知识',
                        type: 'line',
                        data: chartData[1].conversationCount
                    },
                    {
                        name: '其他',
                        type: 'line',
                        data: chartData[2].conversationCount
                    },
                ]
            });
        },
        handleSwitch() {
            this.getMessageStatistics()
        },
        handleChartSwitch() {
            this.getMessageEcharts()
        },
        getMessageStatistics() {
            var params = {
                contentType: this.contentType,
                dateType: this.dateType,
                startTime: this.date?.[0],
                endTime: this.date?.[1],
            };
            messageStatistics(params).then((res) => {
                var data = res.data;
                if (data.success) {
                    this.statisticsData = data.response
                }
            });
        },
        getMessageEcharts() {
            this.chartLoading = true
            var params = {
                startTime: this.chartDate?.[0],
                endTime: this.chartDate?.[1],
            };
            messageEcharts(params).then((res) => {
                this.chartLoading = false
                this.$nextTick(() => {
                    var data = res.data;
                    if (data.success) {
                        this.showChart = true
                        this.$nextTick(() => {
                            this.drawLine(data.response[0].chartTime, data.response);
                        })
                    } else {
                        this.showChart = false
                    }
                })
            });
        },
    },
    mounted() {
        this.getMessageStatistics()
        this.getMessageEcharts()
    },
};
</script>
<style scoped>
::v-deep .statistics-box-card .el-card__body {
    padding: 0;
}

.statistics-wrap {
    padding-bottom: 40px;
}

.box-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box-card-header .title {
    font-weight: 900;
    font-size: 17px;
}

.box-card-content {
    height: 200px;
    display: flex;
}

.box-card-content .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 25%;
}

.item+.item {
    border-left: 1px solid #EBEEF5;
}

.box-card-content .item .number {
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 24px;
}

.chart-date {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}
</style>
