<template>
    <div>
        <!--顶部工具条-->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="用户名称:">
                    <el-select v-model="filters.customerId" clearable filterable remote placeholder="请输入关键词"
                        :loading="userLoading" :remote-method="getCustomerInfoList">
                        <el-option v-for="item in customerList" :key="item.userId" :label="item.trueName"
                            :value="item.userId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="聊天来源:">
                    <el-select v-model="filters.sourceType" clearable>
                        <el-option v-for="item in sourceTypeList" :key="item.key" :label="item.value" :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="问题分类:">
                    <el-select v-model="filters.contentType" clearable placeholder="请选择">
                        <el-option v-for="item in contentTypeList" :key="item.key" :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属知识库:">
                    <el-select v-model="filters.knowledgeId" clearable placeholder="请选择">
                        <el-option v-for="item in knowledgeList" :key="item.knowledgeId" :label="item.knowledgeName"
                            :value="item.knowledgeId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审查状态:">
                    <el-select v-model="filters.checkStatus" clearable placeholder="请选择">
                        <el-option v-for="item in checkStatusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保底消息:">
                    <el-select v-model="filters.isBaseBottom" clearable placeholder="请选择">
                        <el-option v-for="item in isBaseBottomList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户问题:">
                    <el-input v-model="filters.content" @input="(e) => (filters.content = utils.validForbid(e))"
                        placeholder="请输入问题" clearable @clear='clearContent'></el-input>
                </el-form-item>
                <el-form-item label="对话模型:">
                    <el-select v-model="filters.modelType" clearable placeholder="请选择">
                        <el-option v-for="item in modelTypeList" :key="item.key" :label="item.value" :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <el-table :data="messageList" highlight-current-row @current-change="selectCurrentRow" v-loading="listLoading"
            @selection-change="selsChange" style="width: 100%" border>
            <!-- <el-table-column type="selection" width="50">
			</el-table-column> -->
            <!-- <el-table-column type="index" width="80" align="center" :index='indexMethod'> </el-table-column> -->
            <!-- <el-table-column prop="conversationNo" label="会话编号" width="150" align="center"></el-table-column> -->
            <el-table-column label="聊天来源" width="100" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.sourceTypeIdName }}</span>
                    <span style="margin-left: 10px" v-if="scope.row.sourceTypeName">({{ scope.row.sourceTypeName
                        }})</span>
                </template>
            </el-table-column>
            <el-table-column prop="customerName" label="用户名称" width="140">
                <template slot-scope="scope">
                    <div v-if="scope.row.customerName"> {{ scope.row.customerName }}</div>
                    <div v-if="scope.row.customerMobilePhone">{{ scope.row.customerMobilePhone }}</div>
                    <div v-if="scope.row.customerSchool"> {{ scope.row.customerSchool }}</div>
                </template>
            </el-table-column>
            <el-table-column label="用户问题" width align="center">
                <template slot-scope="scope">
                    <el-tag color="rgb(255, 136, 64)" size="mini" effect="dark" type="warning" disable-transitions
                        v-if="scope.row.isShared">题</el-tag>
                    <span style="margin-left: 10px">{{ scope.row.messageContent }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="messageContent" label="用户问题" width="150" align="center" show-overflow-tooltip></el-table-column> -->
            <el-table-column label="回复" width="" align="center">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="light" placement="top-start">
                        <div slot="content" class="tooltip-content">
                            <!-- {{ scope.row.answerContent }} -->
                            <mavon-editor previewBackground="#ffffff" boxShadowStyle="none"
                                v-model="scope.row.answerContent" :editable='false' :toolbarsFlag='false'
                                :subfield='false' :defaultOpen='"preview"' />
                        </div>
                        <span class="ellipsis-text">{{ scope.row.answerContent }}</span>
                    </el-tooltip>
                    <el-button type="text" size="small" v-if="scope.row.knowledgeId"
                        @click.native="handleMessageKnowledgeList(scope.row)">[参考知识点]</el-button>
                    <el-button type="text" size="small" v-if="scope.row.isQuestionContent"
                        @click.native="handleViewTitleAnalysisList(scope.row)">[参考题目解析]</el-button>
                    <!-- <el-button  type="text" size="small"  @click.native="handleViewTitleAnalysisList(scope.row)">[参考题目解析]</el-button> -->
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发送时间" width="200" align="center"></el-table-column>
            <el-table-column prop="contentTypeName" label="问题分类" width="100" align="center"></el-table-column>
            <el-table-column prop="knowledgeName" label="所属知识库" width="100" align="center"></el-table-column>
            <el-table-column prop="modelTypeName" label="对话模型" width="100" align="center"></el-table-column>
            <el-table-column prop="checkStatusName" label="审查状态" width="100" align="center"></el-table-column>
            <!-- <el-table-column prop="isEnable" label="启用标识" width="100" align="center">
				<template slot-scope="scope">
					<el-tag
						:type="scope.row.isEnable == true ? 'success' : 'danger'"
						disable-transitions
					>{{ scope.row.isEnable == true ? "启用" : "禁用" }}
					</el-tag>
				</template>
			</el-table-column> -->
            <el-table-column align="center" label="操作" width="170">
                <template slot-scope="scope">
                    <el-button type="text" size="small"
                        @click.native="handleChatConversation(scope.row)">查看会话</el-button>
                    <el-button type="text" size="small" @click.native="handleImportChat(scope.row)">导入到数据</el-button>
                    <el-button type="text" size="small" v-if="!(scope.row.isFeedback || scope.row.checkStatus)"
                        @click.native="handleFeedback(scope.row)">错误反馈</el-button>
                    <el-button type="text" size="small" v-if="!(scope.row.isFeedback || scope.row.checkStatus)"
                    @click.native="checkMessageFunction(scope.row)">审查</el-button>
                    <el-button type="text" size="small" v-if="!(scope.row.isFeedback || scope.row.checkStatus)"
                    @click.native="handleReCallMessage(scope.row)">退回</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-col :span="24" class="pageBar" align="center">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <el-dialog title="聊天会话" :visible.sync="chatConversationDialogVisible" v-model="chatConversationDialogVisible"
            :close-on-click-modal="false">
            <div class="chat-conversation">
                <div :class="item.isAnswer ? 'left' : 'right'" v-for="item in chatConversationList">
                    <div :class="item.isAnswer ? 'chat-conversation-item-left' : 'chat-conversation-item'">
                        <mavon-editor v-if='item.isAnswer' previewBackground="#ffffff" boxShadowStyle="none"
                            v-model="item.messageContent" :editable='false' :toolbarsFlag='false' :subfield='false'
                            :defaultOpen='"preview"' />
                        <span v-else>{{ item.messageContent }} </span>
                        <!-- <span >{{ item.messageContent }} </span> -->
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog title="问题反馈" :visible.sync="feedbackDialogFormVisible" v-model="feedbackDialogFormVisible"
            :close-on-click-modal="false" :before-close="closeFeedback">
            <el-form :model="feedbackForm" ref="feedbackForm" :rules="feedbackFormRules" label-position="top"
                style="margin-left: 20px; padding-bottom: 0px;">
                <el-form-item label="问题分类" prop="feedbackErrorType">
                    <el-radio-group v-model="feedbackForm.feedbackErrorType">
                        <el-radio v-for="item in errorTypeList" :label="item.key" border>{{ item.value }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否已处理" prop="checkStatus">
                    <el-radio-group v-model="feedbackForm.handleFeedbackStatus">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div v-if="feedbackForm.feedbackErrorType === 3">
                    <el-form-item label="匹配文本: ">
                        <el-input v-model="knowledgeText" autocomplete="off">
                            <el-button slot="append" :disabled="countDown" icon="el-icon-search" type="primary"
                                @click="handleMatch" :loading="matchLoading">{{ countDown ? count : '匹配' }}</el-button>
                        </el-input>
                    </el-form-item>
                    <div style="margin-bottom: 10px">
                        匹配结果:
                    </div>
                    <div v-loading="matchLoading">
                        <div v-if="matchList.length > 0" class="match-result-wrap">
                            <div class="match-result-item" v-for="item in matchList">
                                <div class="match-result-item-header">
                                    <div>
                                        <el-tag size="mini" :type="item.contentTypeId === 1 ? 'success' : 'warning'">{{
            item.contentTypeName }}</el-tag> {{ item.question }}
                                    </div>
                                    <div>
                                        {{ item.difyScore }}
                                    </div>
                                </div>
                                <div class="match-result-item-content" @click="handleUpdateContent(item)">
                                    <el-tooltip class="item" effect="light" placement="top-start">
                                        <div slot="content" class="tooltip-content">
                                            <mavon-editor previewBackground="#ffffff" boxShadowStyle="none"
                                                v-model="item.bookContent" :editable='false' :toolbarsFlag='false'
                                                :subfield='false' :defaultOpen='"preview"' />
                                        </div>
                                        <span class="match-result-ellipsis-text">{{ item.bookContent }}</span>
                                    </el-tooltip>
                                </div>
                                <div class="match-result-item-footer">
                                    <img src="../../../public/icon/book.png" alt="">
                                    {{ item.bookName }}
                                </div>
                            </div>
                        </div>
                        <div v-if="matchList.length == 0 && !matchLoading">
                            <el-result icon="warning" :title="countDown ? `更改后大概需要${count}秒后才能预览展示` : '无匹配结果'">
                            </el-result>
                        </div>
                    </div>
                </div>
                <div v-if="feedbackForm.feedbackErrorType === 4">
                    <el-form-item label="标题(目录名)">
                        <el-input v-model="feedbackForm.bookContentTitle" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-tag :key="tag" v-for="tag in keywordTags" closable :disable-transitions="false"
                            style="margin-right: 10px; margin-top: 5px;" @close="handleKeywordClose(tag)">
                            {{ tag }}
                        </el-tag>
                        <el-input style="width: 20%; margin-right: 10px;" class="input-new-tag" v-if="keywordVisible"
                            v-model="keywordValue" ref="saveTagInput" size="small"
                            @keyup.enter.native="handleKeywordConfirm" @blur="handleKeywordConfirm">
                        </el-input>
                        <el-button v-if="keywordVisible" class="button-new-tag" size="small"
                            @click="handleKeywordConfirm">确认</el-button>
                        <el-button v-else class="button-new-tag" size="small" @click="showKeywordInput">+
                            新增关键字</el-button>
                        <!-- <el-input v-model="form.bookContentKeyword" autocomplete="off"></el-input> -->
                    </el-form-item>
                    <el-form-item label="知识点(问题)">
                        <span slot="label">知识点(问题):</span>
                        <el-button type="primary" size="small" @click="createKnowledgeQuestion">生成知识点问题</el-button>
                        <el-input style="margin-top: 5px;" v-model="feedbackForm.bookContentKnowledgePoints"
                            autocomplete="off" type="textarea" :rows="11"></el-input>
                    </el-form-item>
                    <el-form-item label="内容">
                        <el-input v-model="feedbackForm.bookContent" autocomplete="off" type="textarea"
                            :rows="11"></el-input>
                    </el-form-item>
                    <el-form-item label="图片">
                        <el-upload :action="`/omswebapi/book/uploadImage`" :headers="headers" list-type="picture-card"
                            :on-remove="handlePicRemove" :on-preview="handlePicturePreview" :on-success="upLoadSuccess"
                            :file-list="fileList">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-dialog title="生成相似问题" :visible.sync="dialogCreateQuestion" append-to-body width="20%">
                        <el-form style="margin-top: 20px;" v-if="dialogCreateQuestion" :model="questionForm"
                            ref="questionForm">
                            <el-form-item label="生成数量:" prop="generateQuestionCount">
                                <el-input-number v-model="questionForm.count" auto-complete="off"></el-input-number>
                            </el-form-item>
                        </el-form>
                        <div style="padding-top: 0px; " slot="footer" class="dialog-footer">
                            <el-button @click="dialogCreateQuestion = false">取 消</el-button>
                            <el-button type="primary" @click="handleQuestionFormConfirm" :loading="questionLoading">确
                                定</el-button>
                        </div>
                    </el-dialog>
                </div>
                <div v-if="feedbackForm.feedbackErrorType === 5">
                    <el-form-item label="备注" prop="feedbackContent">
                        <el-input type="textarea" minlength="" v-model="feedbackForm.feedbackContent"
                            rows="5"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="closeFeedback">取 消</el-button>
                <el-button type="primary" @click.native="saveFeedback" :loading="feedbackLoading">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="查看知识点" :visible.sync="viewKnowledgekDialogVisible" v-model="viewKnowledgekDialogVisible"
            :close-on-click-modal="false">
            <div style="overflow-y: scroll;max-height: 600px;">
                <el-collapse accordion v-model="choseMessageKnowledge" v-for="item in messageKnowledgeList">
                    <el-collapse-item :name="item.knowledgeId">
                        <template slot="title">
                            <el-tag size="small" effect="plain" type="info"
                                style="margin-left: 10px;margin-right: 10px;">知识库</el-tag>
                            <span>{{ item.knowledgeName }}</span>
                        </template>
                        <el-collapse v-for="oneContent in item.contents" class="bookContent" v-model="choseBookContent">
                            <el-collapse-item :name="oneContent.contentId">
                                <template #title>
                                    <div class="custom_wrap">
                                        <div class="custom_title">{{ oneContent.bookContentTitle }}</div>
                                        <span style="margin-right: 10px; ">匹配分数: {{ oneContent.difyScore }}</span>
                                    </div>
                                </template>
                                <div>{{ oneContent.bookContent }}</div>
                                <div class="pic-keyword">
                                    <span v-if="oneContent.imageUrls">
                                        <img class="pic-img" v-for="(item, index) in oneContent.imageUrls" :key="index"
                                            :src="item" @click="handlePictureCardPreview(item)" />
                                    </span>
                                </div>
                                <div class="book-name">
                                    <img style=" width: 21px; height: 21px;" src="../../../public/icon/book.png" alt="">
                                    {{ oneContent.bookName }}
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </el-dialog>
        <el-dialog title="查看题目解析" :visible.sync="viewTitleAnalysisDialogVisible"
            v-model="viewTitleAnalysisDialogVisible" :close-on-click-modal="false">
            <p
                style="border-left: 4px solid rgb(50, 115, 245);padding-left: 20px;font-size: 16px;color: rgb(50, 115, 245);">
                单选题
            </p>
            <p style="line-height: 25px;">{{ questionDetailInfo.questionTitle }}</p>
            <p v-for="item in questionDetailInfo.answer">{{ item.answer }}</p>
            <p style="color: rgb(50, 115, 245); ">正确答案：<span style="color: red;">{{ questionDetailInfo.correctAnswer
                    }}</span>
            </p>
            <p style="line-height: 25px;"><span style="color: rgb(50, 115, 245); margin-right: 20px;">解析:</span>{{
            questionDetailInfo.answerAnalysis
        }}</p>
        </el-dialog>
        <el-dialog title="导出excel" :visible.sync="exportExcelDialogVisible" v-model="exportExcelDialogVisible"
            :close-on-click-modal="false">
            <div style="margin-top: 20px">
                <el-radio v-model="exportExcelRadio" :label="false" border size="medium">导出新增聊天记录</el-radio>
                <span>距离上一次导出新增 <span style="color: rgb(201, 21, 30);">{{ newCount }}</span> 条数据</span>
            </div>
            <div style="margin-top: 20px">
                <el-radio v-model="exportExcelRadio" :label="true" border size="medium">导出所有聊天记录</el-radio>
                <span><span style="color: rgb(201, 21, 30);">{{ totalCount }}</span> 条</span>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="exportExcelDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click.native="exportMessageExcel" :loading="exportLoading">导 出</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogPreview" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <el-dialog title="编辑知识点" :visible.sync="updateContentDialog" append-to-body :close-on-click-modal="false">
            <el-form :model="updateContentForm" ref="feedbackForm" :rules="updateContentFormRules" label-position="top"
                style="margin-left: 20px; padding-bottom: 0px;">
                <el-form-item label="标题(目录名)">
                    <el-input v-model="updateContentForm.bookContentTitle" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-tag :key="tag" v-for="tag in updateContentkeywordTags" closable :disable-transitions="false"
                        style="margin-right: 10px; margin-top: 5px;" @close="handleUpdateContentKeywordClose(tag)">
                        {{ tag }}
                    </el-tag>
                    <el-input style="width: 20%; margin-right: 10px;" class="input-new-tag"
                        v-if="updateContentkeywordVisible" v-model="updateContentkeywordValue" ref="saveTagInput"
                        size="small" @keyup.enter.native="handleUpdateContentKeywordConfirm"
                        @blur="handleUpdateContentKeywordConfirm">
                    </el-input>
                    <el-button v-if="updateContentkeywordVisible" class="button-new-tag" size="small"
                        @click="handleUpdateContentKeywordConfirm">确认</el-button>
                    <el-button v-else class="button-new-tag" size="small" @click="showUpdateContentKeywordInput">+
                        新增关键字</el-button>
                    <!-- <el-input v-model="form.bookContentKeyword" autocomplete="off"></el-input> -->
                </el-form-item>
                <el-form-item label="知识点(问题)">
                    <span slot="label">知识点(问题):</span>
                    <el-button type="primary" size="small" @click="createKnowledgeQuestion">生成知识点问题</el-button>
                    <el-input style="margin-top: 5px;" v-model="updateContentForm.bookContentKnowledgePoints"
                        autocomplete="off" type="textarea" :rows="11"></el-input>
                </el-form-item>
                <el-form-item label="内容">
                    <el-input v-model="updateContentForm.bookContent" autocomplete="off" type="textarea"
                        :rows="11"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <el-upload :action="`/omswebapi/book/uploadImage`" :headers="headers" list-type="picture-card"
                        :on-remove="handlePicRemove" :on-preview="handlePicturePreview" :on-success="upLoadSuccess"
                        :file-list="fileList">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="updateContentDialog = false">取 消</el-button>
                <el-button type="primary" @click.native="saveUpdateContent" :loading="updateContentLoading">确
                    定</el-button>
            </div>
            <el-dialog title="生成相似问题" :visible.sync="dialogCreateQuestion" append-to-body width="20%">
                <el-form style="margin-top: 20px;" v-if="dialogCreateQuestion" :model="questionForm" ref="questionForm">
                    <el-form-item label="生成数量:" prop="generateQuestionCount">
                        <el-input-number v-model="questionForm.count" auto-complete="off"></el-input-number>
                    </el-form-item>
                </el-form>
                <div style="padding-top: 0px; " slot="footer" class="dialog-footer">
                    <el-button @click="dialogCreateQuestion = false">取 消</el-button>
                    <el-button type="primary" @click="handleQuestionFormConfirm" :loading="questionLoading">确
                        定</el-button>
                </div>
            </el-dialog>
        </el-dialog>
        <el-dialog title="申领问题" :visible.sync="applyFormVisible" v-model="applyFormVisible"
            :close-on-click-modal="false">
            <el-form :model="applyForm" label-width="130px" ref="editForm">
                <el-form-item label="知识分类:" prop="contentType">
                    <el-radio-group v-model="applyForm.contentType">
                        <el-radio class="mark-radio" border v-for="item in contentTypeList" :key="item.key"
                            :label="item.key">{{
            item.value
        }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="申领数量:" prop="count">
                    <el-input-number v-model="applyForm.count" :precision="0"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="applyFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="applySubmit" :loading="applyLoading">申领</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import { validPhone } from "../../../util/validate";
import {
    getMessageListPage,
    getFeedbackDataPage,
    addFeedback,
    getByNamespace,
    getKnowledgeDataPageList,
    getCustomerList,
    getConversationDetail,
    getMessageKnowledgeList,
    getQuestionDetailsByQuestionId,
    exportMessageExcel,
    getMessageCountForExport,
    checkMessageNoFeedback,
    importChat,
    generateQuestion,
    editBookContent,
    queryTableSearchKnowledge,
    applyCustomerMessage,
    applyBackCustomerMessage
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
export default {
    components: { Toolbar },
    data() {
        return {
            host: process.env.VUE_APP_BASE_URL,
            utils: '',
            messageList: [],
            customerList: [],
            contentTypeList: [],
            knowledgeList: [],
            modelTypeList: [],
            sourceTypeList: [],
            messageKnowledgeList: [],
            checkStatusList: [
                {
                    id: 0,
                    name: '未审查'
                },
                {
                    id: 1,
                    name: '已查无异议'
                },
                {
                    id: 2,
                    name: '已反馈'
                }
            ],
            isBaseBottomList: [
                {
                    id: true,
                    name: '是'
                },
                {
                    id: false,
                    name: '否'
                },
            ],
            errorTypeList: [],
            listLoading: false,
            userLoading: false,
            currentRow: null,
            //列表选中列
            sels: [],
            //增删改查按钮
            buttonList: [],
            filters: {
                customerId: null,
                sourceType: null,
                contentType: null,
                knowledgeId: null,
                modelType: null,
                checkStatus: 0,
                isBaseBottom: null,
                content: null,
                applyUserId: JSON.parse(localStorage.getItem("user")).id ,
            },
            //关于分页的obj
            pages: {
                pageSize: 20, //默认每页条数
                pageArr: [10, 20, 30, 40], //默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            //新增界面是否显示
            addFormVisible: false,
            addLoading: false,
            chatConversationDialogVisible: false,//查看聊天会话详情
            chatConversationList: [],
            feedbackDialogFormVisible: false,
            feedbackLoading: false,
            feedbackFormRules: {
                feedbackErrorType: [
                    { required: true, message: '请选择问题反馈', trigger: 'radio' }
                ],
                feedbackContent: [
                    { required: true, message: '请输入反馈内容', trigger: 'blur' }
                ]
            },
            formLabelWidth: '120px',
            feedbackForm: {
                messageId: 0,
                feedbackErrorType: 3,
                feedbackContent: '',
                bookContentTitle: '',
                bookContentKeyword: '',
                bookContent: '',
                bookContentKnowledgePoints: '',
                imageUrls: [],
                handleFeedbackStatus: 0
            },
            viewKnowledgekDialogVisible: false,
            activeNames: ['1'],
            activeNames2: ['1'],
            choseMessageKnowledge: [],
            choseBookContent: [],
            viewTitleAnalysisDialogVisible: false,//查看题目解析
            questionDetailInfo: {},
            exportExcelDialogVisible: false,//导出excel
            exportExcelRadio: false, //是否权限
            newCount: 0,//新增数量
            totalCount: 0, //总共数量
            exportLoading: false,
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("Token")}`,
            },
            checkLoading: false,
            dialogPreview: false,
            dialogImageUrl: '',
            fileList: [],
            keywordVisible: false,
            keywordTags: [],
            keywordValue: '',
            dialogCreateQuestion: false,
            questionForm: {
                count: 0
            },
            questionLoading: false,
            knowledgeText: '',
            matchLoading: false,
            matchList: [],
            updateContentDialog: false,
            updateContentForm: {
                bookContentTitle: '',
                bookContentKeyword: '',
                bookContent: '',
                bookContentKnowledgePoints: '',
                imageUrls: [],
            },
            updateContentFormRules: {

            },
            updateContentLoading: false,
            updateContentkeywordVisible: false,
            updateContentkeywordTags: [],
            updateContentkeywordValue: '',
            countDown: false,
            count: 10,
            timer: null,
            applyFormVisible: false,
            applyForm: {
                count: 0,
                contentType: null
            },
            applyLoading: false,
            recallLoading: false,
        }
    },
    methods: {
        // 查询
        getListData() {
            this.pages.pageIndex = 1
            this._getListData();
        },
        // 清空筛选框内容，重新获取数据
        clearContent() {
            this._getListData();
        },
        //当前行发生变化时
        selectCurrentRow(val) {
            this.currentRow = val;
        },
        //选项发生变化时
        selsChange: function (sels) {
            this.sels = sels;
        },
        handleChatConversation: function (val) {
            this.chatConversationList = [];
            this.chatConversationDialogVisible = true;
            let params = {
                conversationId: val.conversationId,
                customerId: val.customerId
            };
            getConversationDetail(params).then((res) => {
                if (res.data.success) {
                    this.chatConversationList = res.data.response;
                }
            });
        },
        handleImportChat: function (val) {
            this.$confirm('确认导入吗？', '提示', {}).then(() => {
                let params = [{
                    dataQuestion: val.messageContent,
                    dataType: 1
                }];
                importChat(params).then((res) => {
                    if (res.data.success) {
                        this.$message({
                            message: '导入成功',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            message: '导入失败',
                            type: 'error'
                        });
                    }
                });
            });
        },
        //显示反馈弹框
        handleFeedback: function (val) {
            var _this = this;
            _this.feedbackForm = {
                messageId: val.messageId,
                feedbackErrorType: 3,
                feedbackContent: '',
                handleFeedbackStatus: 0
            }
            let params = {
                answerMessageId: val.answerMessageId
            };
            _this.knowledgeText = val.messageContent
            getMessageKnowledgeList(params).then((res) => {
                if (res.data.success) {
                    this.keywordTags = res.data.response[0].contents[0].bookContentKeyword?.split('|') || []
                    _this.feedbackForm = {
                        ..._this.feedbackForm,
                        bookContentTitle: res.data.response[0].contents[0].bookContentTitle,
                        bookContentKeyword: res.data.response[0].contents[0].bookContentKeyword,
                        bookContent: res.data.response[0].contents[0].bookContent,
                        bookContentKnowledgePoints: res.data.response[0].contents[0].bookContentKnowledgePoints,
                        imageUrls: res.data.response[0].contents[0].imageUrls,
                        messageId: val.messageId,
                        bookContentParentId: res.data.response[0].contents[0].bookContentParentId,
                        bookId: res.data.response[0].contents[0].bookId,
                        bookContentTree: res.data.response[0].contents[0].bookContentTree,
                        bookContentSort: res.data.response[0].contents[0].bookContentSort,
                        contentId: res.data.response[0].contents[0].contentId,
                        isAiAnswer: res.data.response[0].contents[0].isAiAnswer,
                        isGenerateQuestion: res.data.response[0].contents[0].isGenerateQuestion,
                        slotIds: res.data.response[0].contents[0].slotIds,

                    }
                }
            });
            _this.matchLoading = true
            queryTableSearchKnowledge({
                text: val.messageContent
            }).then((res) => {
                _this.matchLoading = false
                if (res.data.success) {
                    _this.matchList = res.data.response;
                }
            });
            _this.feedbackDialogFormVisible = true;
        },
        closeFeedback: function () {
            var _this = this;
            this.feedbackDialogFormVisible = false;
            this._getListData();
            clearInterval(this.timer);
            this.count = 10
            this.countDown = false
            _this.matchList = []
        },
        saveFeedback: function (val) {
            this.$refs.feedbackForm.validate((valid) => {
                if (valid) {
                    this.$confirm('确认提交吗？', '提示', {}).then(() => {
                        this.feedbackLoading = true;
                        let params = Object.assign({}, this.feedbackForm);
                        let keywordTagsStr = ''
                        if (this.keywordTags) {
                            this.keywordTags.map((item, index) => {
                                if (index === this.keywordTags.length - 1) {
                                    keywordTagsStr = keywordTagsStr + item
                                } else {
                                    keywordTagsStr = keywordTagsStr + item + "|"
                                }
                            })
                        }
                        params.bookContentKeyword = keywordTagsStr
                        if (this.feedbackForm.feedbackErrorType == 3) {
                            addFeedback({
                                messageId: params.messageId,
                                feedbackErrorType: params.feedbackErrorType,
                                feedbackContent: params.feedbackContent,
                                contentId: params.contentId,
                                handleFeedbackStatus: params.handleFeedbackStatus
                            }).then((res) => {
                                if (util.isEmt.format(res)) {
                                    this.feedbackLoading = false;
                                    return;
                                }
                                if (res.data.success) {
                                    this.feedbackLoading = false;
                                    this.feedbackDialogFormVisible = false;
                                    this.$message({
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.closeFeedback();
                                    // this._getListData(); 
                                    // this.getDataPermissions();
                                }
                                else {
                                    this.feedbackLoading = false;
                                    this.$message({
                                        message: res.data.message,
                                        type: 'error'
                                    });
                                }
                            });
                        }
                        if (this.feedbackForm.feedbackErrorType == 4) {
                            params.feedbackMessageId = params.messageId
                            editBookContent(params).then(res => {
                                if (res.data.status == 200) {
                                    addFeedback({
                                        messageId: params.messageId,
                                        feedbackErrorType: params.feedbackErrorType,
                                        feedbackContent: params.feedbackContent,
                                        contentId: params.contentId,
                                        handleFeedbackStatus: params.handleFeedbackStatus

                                    }).then((res) => {
                                        if (util.isEmt.format(res)) {
                                            this.feedbackLoading = false;
                                            return;
                                        }
                                        if (res.data.success) {
                                            this.feedbackLoading = false;
                                            this.feedbackDialogFormVisible = false;
                                            this.$message({
                                                message: res.data.message,
                                                type: 'success'
                                            });
                                            this.closeFeedback();
                                            // this._getListData(); 
                                            // this.getDataPermissions();
                                        }
                                        else {
                                            this.feedbackLoading = false;
                                            this.$message({
                                                message: res.data.message,
                                                type: 'error'
                                            });
                                        }
                                    });
                                } else {
                                    this.feedbackLoading = false;
                                    this.$message({
                                        message: '提交失败',
                                        type: "error",
                                    });
                                }
                            })

                        }
                        if (this.feedbackForm.feedbackErrorType == 5) {
                            addFeedback({
                                messageId: params.messageId,
                                feedbackErrorType: params.feedbackErrorType,
                                feedbackContent: params.feedbackContent,
                                contentId: params.contentId,
                                handleFeedbackStatus: params.handleFeedbackStatus
                            }).then((res) => {
                                if (util.isEmt.format(res)) {
                                    this.feedbackLoading = false;
                                    return;
                                }
                                if (res.data.success) {
                                    this.feedbackLoading = false;
                                    this.feedbackDialogFormVisible = false;
                                    this.$message({
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.closeFeedback();
                                    // this._getListData(); 
                                    // this.getDataPermissions();
                                }
                                else {
                                    this.feedbackLoading = false;
                                    this.$message({
                                        message: res.data.message,
                                        type: 'error'
                                    });
                                }
                            });

                        }
                    });
                }
            });
        },
        saveUpdateContent: function (val) {
            this.$confirm('确认提交吗？更改后大概需要10秒后才能预览展示', '提示', {}).then(() => {
                this.updateContentLoading = true;
                let params = Object.assign({}, this.updateContentForm);
                params.feedbackMessageId = this.feedbackForm.messageId
                params.feedbackErrorType = this.feedbackForm.feedbackErrorType
                editBookContent(params).then(res => {
                    this.updateContentLoading = false;
                    if (res.data.status == 200) {
                        this.$message({
                            message: '提交成功, 10秒后预览展示',
                            type: 'success'
                        });
                        this.updateContentDialog = false
                        this.matchList = []
                        this.timer = setInterval(() => {
                            if (this.count > 0) {
                                this.count -= 1;
                                this.countDown = true
                            } else {
                                this.countDown = false
                                this.handleMatch()
                                this.count = 10
                                clearInterval(this.timer);
                            }
                        }, 1000)
                    } else {
                        this.$message({
                            message: '提交失败',
                            type: "error",
                        });
                    }
                })
            });
        },
        //获取消息来源类型
        getSourceType() {
            var _this = this;
            var params = {
                namespace: 'BizService.OmsAicsService.MessageSourceType ',
            };
            getByNamespace(params).then((res) => {
                var data = res.data;
                // var resData = [data.response];
                if (data.success) {
                    _this.sourceTypeList = [...data.response];
                }
            });
        },
        //获取学员问题分类
        getCustomerInfoList(query) {
            var _this = this;
            if (query !== '') {
                _this.userLoading = true;
                var params = {
                    customerName: query,
                };
                setTimeout(() => {
                    getCustomerList(params).then((res) => {
                        this.userLoading = false;
                        var data = res.data;
                        // var resData = [data.response];
                        if (data.success) {
                            _this.customerList = data.response;
                        }
                    });

                }, 200);
            } else {
                _this.customerList = [];
            }
        },
        //获取学员问题分类
        getContentTypeList() {
            var _this = this;
            var params = {
                namespace: 'BizService.OmsAicsService.ContentType',
            };
            getByNamespace(params).then((res) => {
                var data = res.data;
                // var resData = [data.response];
                if (data.success) {
                    _this.contentTypeList = data.response;
                }
            });
        },
        //获取对话模型列表
        getModelTypeList() {
            var _this = this;
            var params = {
                namespace: 'BizService.OmsAicsService.ModelType',
            };
            getByNamespace(params).then((res) => {
                var data = res.data;
                // var resData = [data.response];
                if (data.success) {
                    _this.modelTypeList = data.response;
                }
            });
        },
        //问题反馈类型
        getErrorTypeList() {
            var _this = this;
            var params = {
                namespace: 'BizService.OmsAicsService.FeedbackErrorType',
            };
            getByNamespace(params).then((res) => {
                var data = res.data;
                // var resData = [data.response];
                if (data.success) {
                    _this.errorTypeList = data.response;
                }
            });
        },
        //获取所属知识库
        getKnowledgeList() {
            var _this = this;
            var params = {
                knowledgeName: '',
                pageIndex: 1,
                pageSize: 999
            };
            getKnowledgeDataPageList(params).then((res) => {
                var data = res.data;
                if (data.success) {
                    _this.knowledgeList = [{
                        knowledgeId: 0,
                        knowledgeName: "没有匹配知识库"
                    },
                    ...data.response.data];
                }
            });
        },
        _getListData() {
            this.listLoading = true;
            var params = {
                customerId: this.filters.customerId,
                sourceType: this.filters.sourceType,
                contentType: this.filters.contentType,
                knowledgeId: this.filters.knowledgeId,
                checkStatus: this.filters.checkStatus,
                applyUserId: this.filters.applyUserId,
                modelType: this.filters.modelType,
                isBaseBottom: this.filters.isBaseBottom,
                content: this.filters.content,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
                applyStatus:1
            };
            getMessageListPage(params).then((res) => {
                this.listLoading = false;
                this.messageList = res.data.response.data;

                this.pages.dataCount = res.data.response.dataCount;
                this.pages.pageIndex = res.data.response.pageIndex;
                if (res.data.response.pageSize > 0) {
                    this.pages.pageSize = res.data.response.pageSize;
                }
            });

        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
        callFunction(item) {
            this[item.func].apply(this, item);
        },
        //分页
        handleCurrentChange(val) {
            // if(this.filters.name != '' || this.filters.selectc != false){
            //   this.pages.pageIndex = 1
            // }else{
            this.pages.pageIndex = val;
            // }      
            this._getListData();
        },
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this._getListData();
        },
        toFeedbackIssue() {

        },
        //查看知识点弹框
        handleMessageKnowledgeList(val) {
            this.viewKnowledgekDialogVisible = true;
            this.messageKnowledgeList = [];
            this.choseMessageKnowledge = [];
            this.choseBookContent = [];
            let params = {
                answerMessageId: val.answerMessageId
            };
            getMessageKnowledgeList(params).then((res) => {
                if (res.data.success) {
                    this.messageKnowledgeList = res.data.response;
                    res.data.response.forEach((item) => {
                        this.choseMessageKnowledge.push(item.knowledgeId);
                        item.contents.forEach((oneContent) => {
                            this.choseBookContent.push(oneContent.contentId);
                        });
                        // console.log(item.knowledgeId);
                    });
                }
            });
        },
        handleChange(val) {
            console.log(val);
        },
        handleViewTitleAnalysisList(val) { //查看题目解析
            this.viewTitleAnalysisDialogVisible = true;
            let params = {
                questionId: val.questionId
            };
            getQuestionDetailsByQuestionId(params).then((res) => {
                if (res.data.success) {
                    this.questionDetailInfo = res.data.response;
                }
            });
        },
        handleExportExcel() {
            this.exportExcelDialogVisible = true;
            this.exportExcelRadio = false;
            getMessageCountForExport().then((res) => {
                if (res.data.success) {
                    this.newCount = res.data.response.newCount;
                    this.totalCount = res.data.response.totalCount;
                }
            });
        },
        exportMessageExcel() {
            this.exportLoading = true;
            function createObjectURL(object) { return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object); }
            var xhr = new XMLHttpRequest();
            var formData = new FormData();
            xhr.open("GET", `/omswebapi/message/exportMessageExcel?isAll=${this.exportExcelRadio}`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${window.localStorage.getItem("Token")}`);
            xhr.responseType = 'blob';
            xhr.onload = function (e) {
                if (this.status == 200) {
                    var blob = this.response;
                    var filename = `聊天记录导出.xlsx`;
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        var a = document.createElement('a');
                        var url = createObjectURL(blob);
                        a.href = url;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }

                }
            };
            xhr.send(formData);
            this.exportLoading = false;
        },
        checkMessageFunction(val) {
            this.$confirm('确认审查吗？', '提示', {}).then(() => {
                this.checkLoading = true;
                let params = {
                    messageId: val.messageId
                };
                checkMessageNoFeedback(params).then((res) => {
                    if (util.isEmt.format(res)) {
                        this.checkLoading = false;
                        return;
                    }
                    if (res.data.success) {
                        this.checkLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'success'
                        });
                        val.checkStatus = 1;
                        val.checkStatusName = '已查无异议';
                        val.isFeedback = true;
                        this._getListData();
                    } else {
                        this.checkLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                });
            });
        },
        handlePictureCardPreview(url) {
            this.dialogImageUrl = url;
            this.dialogPreview = true;
        },
        handlePicturePreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogPreview = true;
        },

        handlePicRemove(file) {
            if (file.response) {
                this.feedbackForm.imageUrls = this.feedbackForm.imageUrls.filter(item => item !== file.response.response.fileUrl)
            } else {
                this.feedbackForm.imageUrls = this.feedbackForm.imageUrls.filter(item => item !== file.url)
            }

        },
        upLoadSuccess(res) {
            if (res.success) {
                this.feedbackForm.imageUrls.push(res.response.fileUrl)
            } else {
                this.$message({
                    message: '上传失败, 请重试',
                    type: "error",
                });
            }
        },


        handleKeywordClose(tag) {
            this.keywordTags.splice(this.keywordTags.indexOf(tag), 1);
        },

        showKeywordInput() {
            this.keywordVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleKeywordConfirm() {
            let keywordValue = this.keywordValue;
            if (keywordValue) {
                this.keywordTags.push(keywordValue);
            }
            this.keywordVisible = false;
            this.keywordValue = '';
        },

        handleUpdateContentKeywordClose(tag) {
            this.updateContentkeywordTags.splice(this.updateContentkeywordTags.indexOf(tag), 1);
        },

        showUpdateContentKeywordInput() {
            this.updateContentkeywordVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleUpdateContentKeywordConfirm() {
            let keywordValue = this.updateContentkeywordValue;
            if (keywordValue) {
                this.updateContentkeywordTags.push(keywordValue);
            }
            this.updateContentkeywordVisible = false;
            this.updateContentkeywordValue = '';
        },

        createKnowledgeQuestion() {
            this.dialogCreateQuestion = true
        },
        handleQuestionFormConfirm() {
            this.questionLoading = true
            let params = {
                count: this.questionForm.count,
                question: this.feedbackForm.bookContentTitle,
                answer: this.feedbackForm.bookContent
            }
            generateQuestion(params).then((res) => {
                if (res.data.status === 200) {
                    this.feedbackForm.bookContentKnowledgePoints = `${this.feedbackForm.bookContentKnowledgePoints}\n${res.data.response}`
                    this.$message({
                        message: '生成成功',
                        type: "success",
                    });
                    this.dialogCreateQuestion = false
                    this.questionLoading = false
                } else {
                    this.$message({
                        message: '生成失败, 请重试',
                        type: "error",
                    });
                    this.questionLoading = false

                }
            })
        },
        handleMatch() {
            this.matchLoading = true
            queryTableSearchKnowledge({
                text: this.knowledgeText
            }).then((res) => {
                this.matchLoading = false
                if (res.data.success) {
                    this.matchList = res.data.response;
                }
            });
        },
        handleUpdateContent(val) {
            this.updateContentDialog = true
            this.updateContentkeywordTags = val.bookContentKeyword?.split('|') || []
            this.updateContentForm = {
                messageId: val.messageId,
                feedbackErrorType: 3,
                bookContentTitle: val.bookContentTitle,
                bookContentKeyword: val.bookContentKeyword,
                bookContent: val.bookContent,
                bookContentKnowledgePoints: val.bookContentKnowledgePoints,
                imageUrls: val.imageUrls,
                bookContentParentId: val.bookContentParentId,
                bookId: val.bookId,
                bookContentTree: val.bookContentTree,
                bookContentSort: val.bookContentSort,
                contentId: val.contentId,
                isAiAnswer: val.isAiAnswer,
                isGenerateQuestion: val.isGenerateQuestion,
                slotIds: val.slotIds,
            }
        },
        handleApplyMessage() {
            this.applyFormVisible = true
            this.applyForm = {
                count: 40,
                contentType: null
            }
        },
        applySubmit() {
            this.applyLoading = true
            let params = {
                applyCount: this.applyForm.count,
                contentType: this.applyForm.contentType
            }
            applyCustomerMessage(params).then((res) => {
                if (res.data.status === 200) {
                    this.$message({
                        message: '申领成功',
                        type: "success",
                    });
                    this._getListData();
                    this.applyFormVisible = false
                    this.applyLoading = false
                } else {
                    this.$message({
                        message: '申领成功, 请重试',
                        type: "error",
                    });
                    this.applyLoading = false
                }
            })
        },
        handleReCallMessage(val){
            this.$confirm('确认退回吗？', '提示', {}).then(() => {
                this.recallLoading = true;
                let params = {
                    messageId: val.messageId
                };
                applyBackCustomerMessage(params).then((res) => {
                    if (util.isEmt.format(res)) {
                        this.recallLoading = false;
                        return;
                    }
                    if (res.data.success) {
                        this.recallLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'success'
                        });
                        this._getListData();
                    } else {
                        this.recallLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                });
            });
        }

    },
    mounted() {
        this.utils = util
        this.getSourceType();
        this.getContentTypeList();
        this.getModelTypeList();
        this.getKnowledgeList();
        this.getErrorTypeList();
        this._getListData();
        let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
};
</script>
<style scoped>
.rolesTop {
    width: 100%;
    display: flex;
}

.rolesInp {
    width: 300px;
    margin-right: 20px;
}

.pageBar {
    margin-top: 15px;
}

.ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.match-result-ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 11;
    overflow: hidden;
    text-overflow: ellipsis;
}

::v-deep .el-dialog__body {
    padding: 0px 20px 30px
}

::v-deep .el-collapse {
    border: none;
}

::v-deep .el-collapse-item__wrap {
    border: none;
}

::v-deep .el-collapse-item__header {
    border-bottom: none;
    font-weight: bold;
    font-size: 16px;
    height: auto
}

::v-deep .el-collapse-item__content {
    padding: 0px;
}

::v-deep .bookContent .el-collapse-item {
    margin-bottom: 15px;
}

::v-deep .bookContent .el-collapse-item__header {
    background-color: rgb(242, 243, 245);
    border-bottom: null;
    border-radius: 3px;
    padding-left: 15px;
    font-weight: bold;
    font-size: 14px;
}

::v-deep .bookContent .el-collapse-item__wrap {

    border: 1px solid rgb(64, 158, 255);

    border-radius: 3px;
    margin-top: 15px;
}

::v-deep .bookContent .el-collapse-item__content {
    padding: 10px 15px;
}

.el-collapse-item__content .bookContentTitle {
    font-weight: bold;
}


::v-deep .el-collapse-item__arrow {
    display: none;
}

::v-deep .el-collapse-item__arrow.is-active {
    display: none;
}

::v-deep .v-note-wrapper {
    min-height: 0;
    min-width: 0
}

::v-deep .v-show-content {
    padding: 15px 25px 8px 25px;
}

::v-deep .markdown-body {
    font-size: 14px;
}

::v-deep .markdown-body img {
    width: 100%;
}

::v-deep .el-input-group__append {
    background-color: #409EFF;
}

::v-deep .el-input-group__append button.el-button {
    color: #FFF;
    background-color: #409EFF;
    border-color: #409EFF;
}

.chat-conversation {
    background-color: #F2F3F5;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-height: 600px;
    overflow-y: auto;
}

.chat-conversation .right {
    display: flex;
    flex-direction: row-reverse;
}


.chat-conversation .left {
    display: flex;
    flex-direction: row;
}

.chat-conversation .chat-conversation-item {
    margin-left: 8px;
    background-color: #CEDBF8;
    height: 100%;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 10px 10px 0 10px;
    text-align: right;
}

.chat-conversation .chat-conversation-item-left {
    margin-right: 8px;
    background-color: #FFFFFF;
    height: 100%;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 10px 10px 10px 0;
}

.tooltip-content {
    width: 500px;
    max-height: 400px;
    overflow-y: auto;
}

.pic-img {
    height: 60px;
    width: 60px;
    margin-right: 10px;
    cursor: pointer;

}

.custom_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.custom_wrap .custom_title {
    width: 70%;
}

.book-name {
    width: 100%;
    border-top: 1px solid #E1E1E1;
    padding-top: 5px;
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.match-result-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 400px;
    overflow-y: auto;
}

.match-result-item {
    width: 49%;
    border: 1px solid #E1E1E1;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.match-result-item :hover {
    cursor: pointer;
}

.match-result-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E1E1E1;
    padding: 0 10px;
    padding-bottom: 5px;
    font-weight: bolder
}

.match-result-item-content {
    padding: 10px;
    height: 200px;
    overflow: hidden;
}

.match-result-item-footer {
    border-top: 1px solid #E1E1E1;
    padding: 0 5px;
    padding-top: 5px;
    display: flex;
    align-items: center;
}

.match-result-item-footer img {
    width: 21px;
    height: 21px;
}
</style>