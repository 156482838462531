<template>
  <div class="wrapper">
    <ul class="bg-bubbles">
      <li v-for="n in 10" :key="n+'n'"></li>
      <ol v-for="m in 5"  :key="m+'m'"></ol>
    </ul>
    <div style="height: 15%;"></div>
    <div v-if="moreLoginDialog == 1">
      <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-position="left" label-width="0px" class="demo-ruleForm login-container">
        <h3 class="title">账号登录</h3>
        <el-form-item prop="account">
          <el-input type="text" v-model="ruleForm2.account" @keyup.enter.native="enterKey" auto-complete="off" placeholder="账号"></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input v-model="ruleForm2.checkPass" @keyup.enter.native="enterKey" auto-complete="off" show-password placeholder="密码"></el-input>
        </el-form-item>
        <div style="display:flex;justify-content:space-between;height:50px;line-height:38px;">
          <div>
            <!-- <el-checkbox v-model="checked" class="remember">记住密码</el-checkbox> -->
            <el-button type="text" @click="loginBySMS">短信登录</el-button>
          </div>
          <div>
            <!-- <el-button type="text" @click="moreLoginWay">更多方式登录</el-button> -->
            <el-button type="text" @click="QYScancodeLogin">企业微信扫码登录</el-button>
          </div>
        </div>
        <el-form-item style="width:100%;">
          <el-button type="primary" style="width:100%;" @click.native.prevent="handleSubmit2" :loading="logining">
            {{loginStr}}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="moreLoginDialog == 2">
      <el-form class="demo-ruleForm login-container">
        <div style="display:flex;justify-content:space-between;margin-top: 70px;">
          <el-button type="text" @click="loginBySMS">短信登录</el-button>
          <el-button type="text" @click="QYScancodeLogin">企业微信扫码登录</el-button>
        </div>
        <!-- <div style="margin-bottom: 20px;margin-top: 70px;text-align: right;">
            <el-button type="text" @click="AccApassLogin">账号登录</el-button>
        </div> -->
      </el-form>
    </div>
    <div v-if="LoginSMS">
      <el-form :model="phoneForm" :rules="phoneFormRules" ref="phoneForm" label-position="left" label-width="0px"
               class="demo-ruleForm login-container">
        <!-- <h3 class="title" @click="handleSubmit2">短信登录</h3> -->
        <h3 class="title">短信登录</h3>
        <el-form-item prop="userPhone">
          <el-input type="text" v-model="phoneForm.userPhone" @input="(e) => (phoneForm.userPhone = utils.validForbid(e))" auto-complete="off" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="verificationCode">
          <el-input v-model="phoneForm.verificationCode" @input="(e) => (phoneForm.verificationCode = utils.validForbid(e))" @keyup.enter.native="messageEnterKey" style="width: 50%;" auto-complete="off" placeholder="请输入短信验证码"></el-input>
          <el-button type="primary" :loading="codeLogining" style="margin-left: 10px;" @click.native.prevent="getVerCodeFun">
            {{getVerCode}}{{getVerCode1}}
          </el-button>
        </el-form-item>
        <div class="formeSelec">
          <!-- <div>

          </div> -->
          <!-- <div style="margin-bottom: 13px;margin-top: -7px;">
              <el-button type="text" @click="AccApassLogin">账号登录</el-button>
              <el-button type="text" @click="QYScancodeLogin">企业微信扫码登录</el-button>
          </div> -->
          <div style="display:flex;justify-content:space-between;margin-top: 20px;">
            <!-- <el-button type="text" @click="AccApassLogin">账号登录</el-button> -->
            <!-- <el-button type="text" @click="QYScancodeLogin">企业微信扫码登录</el-button> -->
          </div>
        </div>
        <!-- <div style="margin-bottom:15px;">
            <div class="accountList" v-for="(item,index) in accountArr" :key="index">
                <el-button type="text">{{item.Name}}</el-button>
            </div>
        </div> -->
        <el-form-item style="width:100%;" v-if="accountArr.length > 1">
          <el-button v-for="(item,index) in accountArr" :key="index" type="primary" style="width:100%;margin-left: 0;margin-bottom:10px;" @click.native.prevent="handlePhoneSubmit(item)" :loading="phoneSubmitLogining">
            {{item}}
          </el-button>
        </el-form-item>
        <el-form-item style="width:100%;" v-else>
          <el-button type="primary" style="width:100%;" @click.native.prevent="handlePhoneSubmit(accountOne)" :loading="phoneSubmitLogining">
            {{loginStr}}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="QYCodeLogin">
      <el-form label-position="left" label-width="0px" class="demo-ruleForm login-container">
        <div class="login_content_wrap">
          <div id="qr_con" class="">
            <div class="">
              <div align="center" id="wx_reg"></div>
            </div>
          </div>
        </div>
        <div style="display:flex;justify-content:space-between;margin-top: 0px;">
          <el-button type="text" @click="loginBySMS">短信登录</el-button>
          <!-- <el-button type="text" @click="AccApassLogin">账号登录</el-button> -->
        </div>
        <!-- <div style="margin-bottom: 13px;margin-top: -7px;text-align: right;">
            <el-button type="text" @click="AccApassLogin">账号登录</el-button>
        </div> -->
      </el-form>
    </div>
  </div>
</template>
<script>
import {requestLogin,getUserByToken,getNavigationBar, getLoginCode, codelogin} from '../api/api';
import router from '@/router'
import util from '../../util/date'
import {filterAsyncRouter} from '@/router/index'
import Cookies from 'js-cookie'
import { validPhone,valididentityCard } from "../../util/validate";
export default {
  data() {
    //验证手机号
    let isPhoneNumberNum = (rule, value, callback) => {
      if (!validPhone(value)) {
        return callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      utils: '',
      loginStr: '登录',
      logining: false,
      ruleForm2: {
        account: '',
        checkPass: ''
      },
      rules2: {
        account: [
          {required: true, message: '请输入账号', trigger: 'blur'},
        ],
        checkPass: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ]
      },
      checked: false,
      // 选择登录方式
      moreLoginDialog: 0,
      LoginSMS: true,
      codeLogining: false,
      getVerCode: '获取验证码',
      getVerCode1: '',
      phoneSubmitLogining: false,
      accountArr: [], //账号集合
      accountOne: '', //一个账号
      phoneForm: {
        userPhone: '',
        verificationCode: '',
      },
      phoneFormRules: {
        userPhone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          // { validator: isPhoneNumberNum },
        ],
        verificationCode: [
          {required: true, message: '请输入短信验证码', trigger: 'blur'},
        ],
      },
      // 企业微信扫码登录
      QYCodeLogin: false,
    };
  },
  methods:{
    // 回车
    enterKey() {
      if(!this.logining){
        this.handleSubmit2();
      }
    },
    // 验证码回车
    messageEnterKey() {
      if(!this.phoneSubmitLogining) {
        this.handlePhoneSubmit();
      }
    },
    // 获取 Token
    handleSubmit2(ev) {
      var _this = this;
      // this.$refs.ruleForm2.validate((valid) => {
      //     if (valid) {
      // this.ruleForm2.account = this.phoneForm.userPhone
      // this.ruleForm2.checkPass = this.phoneForm.verificationCode
      if(this.ruleForm2.account && this.ruleForm2.checkPass) {
        this.logining = true;
        var loginParams = {account: this.ruleForm2.account, password: this.ruleForm2.checkPass};
        _this.loginStr = "登录中...";
        requestLogin(loginParams).then(data => {
          console.log(data);
          if (!data.success) {
            _this.$message({
              // message: data.message,
              message: '用户名或密码错误！',
              type: 'error'
            });
            _this.logining = false;
            _this.loginStr = "重新登录";
            // _this.closeAlert()
          } else {
            var token = data.response.token;
            _this.$store.commit("saveToken", token);
            var curTime = new Date();
            var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + data.response.expiresIn));
            _this.$store.commit("saveTokenExpire", expiredate);
            window.localStorage.refreshtime = expiredate;
            window.localStorage.expiresIn = data.response.expiresIn;
            // _this.closeAlert()
            // _this.openAlert("成功获取Token，等待服务器返回用户信息...")
            _this.loginStr = "成功获取Token，等待服务器返回用户信息...";
            _this.getUserInfoByToken(token)
          }
        })
            .catch(
                setTimeout(function(){
                  _this.logining = false,
                      _this.loginStr = "重新登录"
                },5000)
            )
      } else {
        _this.$message.warning("请输入用户名或密码！")
      }
      // } else {
      //     console.log('error submit!!');
      //     return false;
      // }
      // });
    },
    // 获取用户数据
    getUserInfoByToken(token) {
      var _this = this;
      var loginParams = {token: token};
      getUserByToken(loginParams).then(data => {
        if (!data.success) {
          _this.$message({
            message: data.message,
            type: 'error'
          });
        } else {
          // _this.closeAlert()
          // _this.openAlert("接收到用户数据，开始初始化路由树...")
          if(data.response.isInitialization){
            router.replace({
              path: "/callBackLogin",
              query: {redirect: router.currentRoute.fullPath,isRemove:"true",}
            });
          }else{
            // 记住密码
            if(this.checked){
              var strMi = this.ruleForm2.account+'|'+this.ruleForm2.checkPass
              var MiStr = util.compileStr(strMi)
              Cookies.set('ASP.pass',MiStr,{expires: 7,});
            }else{
              Cookies.remove('ASP.pass');
            }
            _this.loginStr = "接收到用户数据，开始初始化路由树...";
            window.localStorage.user = JSON.stringify(data.response)
            if (data.response.id > 0) {
              _this.GetNavigationBar(data.response.id)
            }
          }
        }
      });
    },
    // 获取路由树
    GetNavigationBar(userID) {
      var _this = this;
      var loginParams = {userId: userID, t: new Date(), type: 164};
      getNavigationBar(loginParams).then(data => {
        _this.logining = false;
        if (!data.success) {
          _this.$message({
            message: data.message,
            type: 'error'
          });
        } else {
          // _this.closeAlert()
          let userinfo = JSON.parse(window.localStorage.user ? window.localStorage.user : null);
          _this.$notify({
            type: "success",
            message: `登录成功 \n 欢迎：${userinfo.name} ！Token 将在 ${window.localStorage.expiresIn / 60} 分钟后过期！`,
            duration: 6000
          });
          window.localStorage.router = (JSON.stringify(data.response.children));
          let getRouter = data.response.children//后台拿到路由
          getRouter = filterAsyncRouter(getRouter) //过滤路由
          router.$addRoutes(getRouter) //动态添加路由
          // console.log("路由",_this.$route.query.redirect)
          _this.$router.replace(_this.$route.query.redirect ? _this.$route.query.redirect : this.getPresentRouter(getRouter));
          // window.location.reload()
        }
      });
    },
    getPresentRouter(getRouter) {
      if(getRouter[0].path == '/') {
          return getRouter[0].path
      } else {
          for(var i = 0; i < getRouter.length; i++) {
              if(getRouter[i].children.length > 0) {
                  for(var a = 0; a < getRouter[i].children.length; a++) {
                      if(getRouter[i].children[a].path) {
                          return getRouter[i].children[a].path
                      }
                  }
              }
          }
      }
    },
    // 更多登录方式
    moreLoginWay() {
      this.moreLoginDialog = 2
      this.LoginSMS = false
      this.QYCodeLogin = false
    },
    // 返回到账号登录
    AccApassLogin() {
      this.moreLoginDialog = 1
      this.LoginSMS = false
      this.QYCodeLogin = false
    },
    // 短信登录
    loginBySMS() {
      this.moreLoginDialog = 0
      this.LoginSMS = true
      this.QYCodeLogin = false
    },
    // 企业微信扫码登录
    QYScancodeLogin() {
      this.$message.warning("功能正在开发中...")
      return
      let base = window.location.origin
      this.moreLoginDialog = 0
      this.LoginSMS = false
      this.QYCodeLogin = true
      // https://drai.qddf-tech.com/#/login 1000003
      // https://aidr.qddf-tech.com/#/login  1000006
      setTimeout(() => {
        var wwLogin = new WwLogin({
          "id": "wx_reg",
          "appid": "wwb0decec2f61ff9de",
          // "agentid": base == "http://fftm.wanghuolou.cn:90" ? "1000025" : "1000006",
          "agentid": 1000025,
          "redirect_uri": base + "/fftmwebapi/login/wxWorkDologin",
          "state": "",
          "href": "",//”href”是可以自己定义CSS样式，可以找这个https://aiznst-crm.qddf.tech/css/wework.css
          "lang": "zh",
        });
      })
      // wwLogin.destroyed()
    },
    // 获取验证码
    getVerCodeFun() {
      this.accountArr = []  //账号集合
      this.accountOne = ''  //一个账号
      if(this.phoneForm.userPhone && validPhone(this.phoneForm.userPhone)){
        this.$confirm("将要获取验证码", "提示", {}).then(() => {
          var params = {
            phone: this.phoneForm.userPhone,
          }
          getLoginCode(params).then(res => {
            var result = res
            this.codeLogining = false
            if(result.success){
              this.sendPhone();
              this.getVerCode1 = "秒后重新获取"
              this.$message({
                message: '验证码发送成功！',
                type: 'success'
              });
              // if(result.Response.length == 1) {
              //     this.accountOne = result.Response[0]
              // }else if(result.Response.length > 1) {
              //     this.accountArr = result.Response
              // }else{
              //     this.$message({
              //         message: "暂无账号",
              //         type: 'error'
              //     });
              // }
              var nowTimer = new Date()
              sessionStorage.setItem("nowTimer",nowTimer)
            }else{
              this.$message({
                message: result.message,
                type: 'error'
              });
              this.codeLogining = false
            }
          })
        })
            .catch(() => {})
      }else{
        this.$message.warning("请输入正确的手机号！")
      }
    },
    // 手机验证码登录
    handlePhoneSubmit(e) {
      var _this = this;
      this.$refs.phoneForm.validate((valid) => {
        if (valid) {
          if(Number(this.phoneForm.userPhone) == this.phoneForm.userPhone) {
            var params = {
              phone: this.phoneForm.userPhone,
              code: this.phoneForm.verificationCode,
            }
            if (!validPhone(this.phoneForm.userPhone)) {
              this.$message.warning("请输入正确的手机号")
              return
            }
            this.phoneSubmitLogining = true
            codelogin(params).then((res) => {
              var result = res
              if(result.success) {
                var token = result.response.token;
                _this.$store.commit("saveToken", token);
                var curTime = new Date();
                var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + result.response.expiresIn));
                _this.$store.commit("saveTokenExpire", expiredate);
                window.localStorage.refreshtime = expiredate;
                window.localStorage.expiresIn = result.response.expiresIn;
                _this.getUserInfoByToken(token)
              }else{
                this.$message.error(result.message)
                this.phoneSubmitLogining = false
              }
            })
          } else {
            this.ruleForm2.account = this.phoneForm.userPhone
            this.ruleForm2.checkPass = this.phoneForm.verificationCode
            this.handleSubmit2();
          }
        }
      });
    },
    // 倒计时
    sendPhone(){
      const TIME_COUNT = sessionStorage.getItem("codeData") || 60; //  更改倒计时时间
      if (!this.timer) {
        this.getVerCode = TIME_COUNT;
        this.codeLogining = true;
        this.timer = setInterval(() => {
          if (this.getVerCode > 0 && this.getVerCode <= TIME_COUNT) {
            this.getVerCode--;
            sessionStorage.setItem("codeData",this.getVerCode)
          } else {
            this.codeLogining = false;
            this.getVerCode1 = ""
            this.getVerCode = "获取验证码"
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
            sessionStorage.removeItem("codeData")
            sessionStorage.removeItem("nowTimer")
          }
        }, 1000);
      }
    },
  },
  created() {
    this.utils = util
    var ASP_Pass = Cookies.get("ASP.pass");
    var OpenStr = util.uncompileStr(ASP_Pass)
    if(ASP_Pass){
      this.ruleForm2.account = util.getCaption(OpenStr,0)
      this.ruleForm2.checkPass = util.getCaption(OpenStr,1)
      this.checked = true
    }else{
      this.checked = false
    }
    // 企业微信扫码登录
    var wxLoginToken = this.$route.query.token
    var wxLoginExpiresIn = this.$route.query.expiresIn
    if(wxLoginToken) {
      this.$store.commit("saveToken", wxLoginToken);
      var curTime = new Date();
      var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + wxLoginExpiresIn));
      this.$store.commit("saveTokenExpire", expiredate);
      window.localStorage.refreshtime = expiredate;
      window.localStorage.expiresIn = wxLoginExpiresIn;
      this.logining = true
      this.loginStr = "成功获取Token，等待服务器返回用户信息...";
      this.getUserInfoByToken(wxLoginToken)
    } else {
      // this.QYScancodeLogin()
    }
  }
}
</script>
<style>
.bg {
  margin: 0px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url(../assets/loginbck.png) no-repeat top left;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.login-container {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin: auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
  z-index: 9999;
  position: relative;
}
.login-container .title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}
.login-container .remember {
  margin: 0px 0px 25px 0px;
}
.wrapper {
  background: #50a3a2;
  background: -webkit-linear-gradient(top left, #50a3a2 0%, #53e3a6 100%);
  background: linear-gradient(to bottom right, #127c7b 0, #50a3a2);
  opacity: 0.8;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.wrapper.form-success .containerLogin h1 {
  -webkit-transform: translateY(85px);
  -ms-transform: translateY(85px);
  transform: translateY(85px);
}
.containerLogin {
  max-width: 600px;
  margin: 0 auto;
  padding: 80px 0;
  height: 400px;
  text-align: center;
}
.containerLogin h1 {
  font-size: 40px;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-timing-function: ease-in-put;
  transition-timing-function: ease-in-put;
  font-weight: 200;
}
.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.bg-bubbles li, .bg-bubbles ol {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  bottom: -160px;
  -webkit-animation: square 25s infinite;
  animation: square 25s infinite;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}
ol {
  padding: 0 !important;
}
.bg-bubbles ol:nth-child(11) {
  left: 10%;
  top: 10%;
  width: 20px;
  height: 20px;
}
.bg-bubbles ol:nth-child(12) {
  left: 20%;
  top: 40%;

  width: 60px;
  height: 60px;
}
.bg-bubbles ol:nth-child(13) {
  left: 65%;
  top: 30%;

  width: 100px;
  height: 60px;
}
.bg-bubbles ol:nth-child(14) {
  left: 70%;
  top: 30%;
  width: 100px;
  height: 150px;
}
.bg-bubbles ol:nth-child(15) {
  left: 50%;
  top: 70%;

  width: 40px;
  height: 60px;
}
.bg-bubbles li:nth-child(1) {
  left: 10%;
}
.bg-bubbles li:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 17s;
  animation-duration: 17s;
}
.bg-bubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.bg-bubbles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-duration: 22s;
  animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}
.bg-bubbles li:nth-child(5) {
  left: 70%;
}
.bg-bubbles li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}
.bg-bubbles li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}
.bg-bubbles li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
}
.bg-bubbles li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
  background-color: rgba(255, 255, 255, 0.3);
}
.bg-bubbles li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
}
@-webkit-keyframes square {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}
@keyframes square {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}
.content-az {
  padding: 0 !important;
  border: none !important;
}
</style>
