<template>
    <div>
        <!--顶部工具条-->
        <el-col :span="24">
            <el-form :inline="true">   
                <el-form-item label="用户问题:">
                    <el-input v-model="filters.content" @input="(e) => (filters.content = utils.validForbid(e))"
                        placeholder="请输入问题" clearable @clear='clearContent'></el-input>
                </el-form-item>
                <el-form-item label="问题来源:">
                    <el-select v-model="filters.feedbackType" clearable placeholder="请选择">
                        <el-option
                            v-for="item in feedbackTypeList"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="问题分类:">
                    <el-select v-model="filters.feedbackErrorType" clearable placeholder="请选择">
                        <el-option
                            v-for="item in feedbackErrorTypeList"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>      
                <el-form-item label="状态:">
                    <el-select v-model="filters.isFeedbackHandled" clearable placeholder="请选择">
                        <el-option v-for="item in isFeedbackHandledData" :key="item.value" :label="item.name" :value="item.value">
						</el-option>
                    </el-select>
                </el-form-item>                
                <el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
            </el-form>
        </el-col>
        <el-table
			:data="feedbackDataList"
			highlight-current-row
			@current-change="selectCurrentRow"
			v-loading="listLoading"
			@selection-change="selsChange"
			style="width: 100%"
            border
		>
        <!-- <el-table-column prop="conversationNo" label="会话编号" width="100" align="center"></el-table-column> -->
        <el-table-column prop="feedbackTypeName" label="问题来源" width="100" align="center"></el-table-column>
        <el-table-column label="用户问题" align="center" width="150">
                <template slot-scope="scope">
                    <el-tag  color="rgb(255, 136, 64)" size="mini" effect="dark"
                    type="warning"  disable-transitions  v-if="scope.row.isShared" >题</el-tag>
                 <span style="margin-left: 10px">{{ scope.row.messageContent }}</span>
                </template>
            </el-table-column>
        <!-- <el-table-column prop="answerContent" label="回复" align="center"></el-table-column> -->
        <el-table-column label="回复" width="" align="center">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="light" placement="top-start">
                        <div slot="content" class="tooltip-content">
                            <!-- {{ scope.row.answerContent }} -->
                            <mavon-editor previewBackground="#ffffff" boxShadowStyle="none" v-model="scope.row.answerContent" :editable='false' :toolbarsFlag='false' :subfield='false' :defaultOpen='"preview"'/>
                        </div>
                        <span  class="ellipsis-text">{{ scope.row.answerContent }}</span>
                    </el-tooltip>
                    <el-button  type="text" size="small" v-if="scope.row.knowledgeId" @click.native="handleMessageKnowledgeList(scope.row)">[参考知识点]</el-button>
                    <el-button  type="text" size="small" v-if="scope.row.isQuestionContent" @click.native="handleViewTitleAnalysisList(scope.row)">[参考题目解析]</el-button>
                    <!-- <el-button  type="text" size="small"  @click.native="handleViewTitleAnalysisList(scope.row)">[参考题目解析]</el-button> -->
                </template>                
            </el-table-column>
        <el-table-column prop="contentTypeName" label="原分类" width="100" align="center"></el-table-column>
        <el-table-column prop="knowledgeName" label="原所属知识库" width="120" align="center"></el-table-column>
        <el-table-column prop="feedbackErrorTypeName" label="问题分类" width="100" align="center"></el-table-column>
        <el-table-column prop="feedbackContent" label="反馈问题"  align="center" width="150"></el-table-column>
        <el-table-column prop="feedbackTime" label="反馈时间"  align="center" width="100"></el-table-column>
        <el-table-column prop="isFeedbackHandled" label="状态" width="100" align="center">
            <template slot-scope="scope">
                <span :class="scope.row.isFeedbackHandled?'handleColor':'untreatedColor'" >{{ scope.row.isFeedbackHandled?"已处理":"待处理" }}</span> 
            </template>
        </el-table-column>
        <el-table-column prop="checkTime" label="审查时间" width="100" align="center"></el-table-column>
        <el-table-column prop="feedbackCreateUserName" label="创建人" width="100" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建人时间" width="100" align="center"></el-table-column>
        <!-- <el-table-column align="center" label="操作" width="100">
                <template slot-scope="scope"> 
                    <el-button type="text" size="small" v-if="!scope.row.isFeedbackHandled" @click.native="handle(scope.row)" >已处理</el-button>                
                    <el-button type="text" size="small" v-if="scope.row.feedbackType==1 && scope.row.feedbackErrorType==null" @click.native="handleFeedback(scope.row)" >错误反馈</el-button>
                </template>
            </el-table-column> -->
        </el-table>
        <el-col :span="24" class="pageBar" align="center" >
			<el-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pages.pageIndex"
			:page-sizes="pages.pageArr"
			:page-size="pages.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="pages.dataCount"
			>
			</el-pagination>
		</el-col>
        
        <el-dialog title="问题反馈" :visible.sync="feedbackDialogFormVisible"  v-model="feedbackDialogFormVisible" 
        :close-on-click-modal="false" >
            <el-form :model="feedbackForm" ref="feedbackForm" :rules="feedbackFormRules" label-position="top" style="margin-left: 20px; padding-bottom: 0px;">
                <el-form-item label="问题分类" prop="feedbackErrorType">
                    <el-radio-group v-model="feedbackForm.feedbackErrorType" size="small"  >
                        <el-radio v-for="item in feedbackErrorTypeList" :label="item.key"  border >{{ item.value }}</el-radio>
                    </el-radio-group>                     
                </el-form-item>            
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="closeFeedback">取 消</el-button>
                <el-button type="primary" @click.native="saveFeedback" :loading="feedbackLoading">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="查看知识点" :visible.sync="viewKnowledgekDialogVisible"  v-model="viewKnowledgekDialogVisible" 
        :close-on-click-modal="false" >
            <div style="overflow-y: scroll;max-height: 600px;">            
                <el-collapse accordion  v-model="choseMessageKnowledge" v-for="item in messageKnowledgeList">
                    <el-collapse-item :name="item.knowledgeId">
                        <template slot="title">
                            <el-tag  size="small" effect="plain"  type="info" style="margin-left: 10px;margin-right: 10px;"  >知识库</el-tag>
                            <span >{{item.knowledgeName}}</span> 
                        </template>
                        <el-collapse v-for="oneContent in item.contents" class="bookContent" v-model="choseBookContent" >
                            <el-collapse-item :name="oneContent.contentId">
                            <template #title >
                                <div class="custom_title">{{oneContent.bookContentTitle}}</div>
                            </template>
                            <div>{{ oneContent.bookContent }}</div>
                        </el-collapse-item>
                        </el-collapse>
                    </el-collapse-item>                
                </el-collapse>
            </div>
        </el-dialog>
        <el-dialog title="查看题目解析" :visible.sync="viewTitleAnalysisDialogVisible"  v-model="viewTitleAnalysisDialogVisible" 
        :close-on-click-modal="false" >
            <p style="border-left: 4px solid rgb(50, 115, 245);padding-left: 20px;font-size: 16px;color: rgb(50, 115, 245);">单选题</p>
            <p style="line-height: 25px;">{{ questionDetailInfo.questionTitle }}</p>
            <p v-for="item in questionDetailInfo.answer">{{item.answer}}</p>
            <p style="color: rgb(50, 115, 245); ">正确答案：<span style="color: red;">{{questionDetailInfo.correctAnswer}}</span></p>
            <p style="line-height: 25px;"><span style="color: rgb(50, 115, 245); margin-right: 20px;">解析:</span>{{ questionDetailInfo.answerAnalysis
 }}</p>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import { validPhone } from "../../../util/validate";
import {
    getByNamespace,
    getFeedbackDataPage,
    handleFeedbackMessage,
    changeCustomerFeedback,
    getMessageKnowledgeList,
    getQuestionDetailsByQuestionId
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from "qs";
export default {
    components: { Toolbar },
    data(){
        return {
            utils: '',
            feedbackErrorTypeList:[],
            feedbackTypeList: [],
            feedbackDataList:[],
            //增删改查按钮
			buttonList: [],
            isFeedbackHandledData: [{
				value: false,
				name: '待处理'
			}, {
				value: true,
				name: '已处理'
			}],
            listLoading: false,
            handlefeedbackLoading:false,
            currentRow: null,
            //列表选中列
			sels: [],
            filters: {
				feedbackErrorType: null,
                feedbackType:null,
                isFeedbackHandled:null,
                content: ''
			},
            //关于分页的obj
			pages: {
				pageSize: 20, //默认每页条数
				pageArr: [10, 20, 30, 40], //默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
            feedbackForm: {
                messageId: 0,
                feedbackErrorType: 1
            },
            feedbackDialogFormVisible:false,
            feedbackFormRules: {
                feedbackErrorType: [
					{required: true, message: '请选择问题反馈', trigger: 'radio'}
				]			
			},
            feedbackLoading: false,
            viewKnowledgekDialogVisible:false,
            choseMessageKnowledge:[],
            messageKnowledgeList:[],
            choseBookContent:[],  
            viewTitleAnalysisDialogVisible:false,//查看题目解析
            questionDetailInfo:{},
        }
    },
    methods: {
        clearContent(){
			this.getDataList()
		},
        //当前行发生变化时
		selectCurrentRow(val) {
			this.currentRow = val;
		},
        //选项发生变化时
		selsChange: function (sels) {
			this.sels = sels;
		},
        callFunction(item) {
            this[item.func].apply(this, item);
        },
        //分页
        handleCurrentChange(val) {
			// if(this.filters.name != '' || this.filters.selectc != false){
			//   this.pages.pageIndex = 1
			// }else{
				this.pages.pageIndex = val;
			// }      
			this.getDataList();
		},
        //获取问题来源
        getFeedbackErrorTypeList(){
            var _this = this;
            var params = {
				namespace: 'BizService.OmsAicsService.FeedbackErrorType',
			};
            getByNamespace(params).then((res)=>{
                var data = res.data;
				// var resData = [data.response];
                if (data.success) {
                    _this.feedbackErrorTypeList = data.response;
				}
            });
        },
        //获取反馈类型
        getfeedbackTypeList(){
            var _this = this;
            var params = {
				namespace: 'BizService.OmsAicsService.FeedbackType',
			};
            getByNamespace(params).then((res)=>{
                var data = res.data;
				// var resData = [data.response];
                if (data.success) {
                    _this.feedbackTypeList = data.response;
				}
            });
        },
        getDataList(){
            this.listLoading = true;
            var params = {
                feedbackErrorType:this.filters.feedbackErrorType,
                feedbackType:this.filters.feedbackType,
                isFeedbackHandled:this.filters.isFeedbackHandled,
                content:this.filters.content,
                pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
            };
            getFeedbackDataPage(params).then((res) => {
                this.listLoading = false;
				this.feedbackDataList = res.data.response.data;				

				this.pages.dataCount = res.data.response.dataCount;
				this.pages.pageIndex = res.data.response.pageIndex;
				if (res.data.response.pageSize > 0) {
					this.pages.pageSize = res.data.response.pageSize;
				}
			});
        },
        handleSizeChange(val) {
			this.pages.pageSize = val;     
			this.getDataList();
		},
        handle(){
            let row = this.currentRow;
            this.$confirm('该问题是否已处理？', '处理提示', {}).then(() => {
                this.handlefeedbackLoading = true;
                let params ={
                    messageId:row.messageId
                }
                handleFeedbackMessage(params).then((res)=>{
                    if (util.isEmt.format(res)) {
                        this.handlefeedbackLoading = false;
                        return;
                    }
                    if (res.data.success) {
                        this.handlefeedbackLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'success'
                        });
                        this.getDataList();
                        // this.getDataPermissions();
                    }
                    else {
                        this.handlefeedbackLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                });
            });
            
        },
        //显示反馈弹框
        handleFeedback:function(val){
            var _this = this;
            _this.feedbackForm.messageId=val.messageId;
            _this.feedbackForm.feedbackErrorType=1;
            _this.feedbackForm.feedbackContent='';
            _this.feedbackDialogFormVisible=true;
        },
        closeFeedback:function(){
            var _this = this;
            this.feedbackDialogFormVisible = false;
            _this.feedbackForm.messageId=1;
            _this.feedbackForm.feedbackErrorType=1;
            this.getDataList();
        },
        saveFeedback:function(val){
            this.$refs.feedbackForm.validate((valid) => {
				if (valid) {
                    this.$confirm('确认提交吗？', '提示', {}).then(() => {
                        this.feedbackLoading = true;
                        let params = Object.assign({}, this.feedbackForm); 
                        changeCustomerFeedback(params).then((res) => {
							if (util.isEmt.format(res)) {
								this.feedbackLoading = false;
								return;
							}
							if (res.data.success) {
								this.feedbackLoading = false;
                                this.feedbackDialogFormVisible=false;
								this.$message({
									message: res.data.message,
									type: 'success'
								});
								this.closeFeedback();
                                // this.getListData(); 
								// this.getDataPermissions();
							}
							else {
								this.feedbackLoading = false;
								this.$message({
									message: res.data.message,
									type: 'error'
								});
							}
						});
                    });
                }
            });
        },
        //查看知识点弹框
        handleMessageKnowledgeList(val){
            this.viewKnowledgekDialogVisible=true;
            this.messageKnowledgeList=[];
            this.choseMessageKnowledge=[];
            this.choseBookContent=[];
            let params ={
                answerMessageId:val.answerMessageId
            };
            getMessageKnowledgeList(params).then((res)=>{
                if (res.data.success) {
                    this.messageKnowledgeList=res.data.response  ;
                    res.data.response.forEach((item)=>{
                        this.choseMessageKnowledge.push(item.knowledgeId);
                        item.contents.forEach((oneContent)=>{
                            this.choseBookContent.push(oneContent.contentId);
                        });
                        // console.log(item.knowledgeId);
                    });
                }
            });
        }, 
        handleViewTitleAnalysisList(val){ //查看题目解析
            this.viewTitleAnalysisDialogVisible=true;
            let params ={
                questionId:val.questionId
            };
            getQuestionDetailsByQuestionId(params).then((res)=>{
                if (res.data.success) {
                    this.questionDetailInfo=res.data.response  ;
                }
            });
        },  
    },
    mounted() {
        this.getDataList();
        this.getFeedbackErrorTypeList();
        this.getfeedbackTypeList();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.utils = util
    }
};
</script>
<style  scoped>
.rolesTop {
	width: 100%;
	display: flex;
}

.rolesInp {
	width: 300px;
	margin-right: 20px;
}

.pageBar {
  	margin-top: 15px;
}

.handleColor{
    color:rgb(22, 186, 16);
}

.untreatedColor{
    color:rgb(255, 136, 64);
}


.ellipsis-text{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

::v-deep .el-dialog__body{
    padding:0px 20px 30px
}

::v-deep .el-collapse{
    border:none;
}

::v-deep .el-collapse-item__wrap{
    border:none;
}

::v-deep  .el-collapse-item__header{
    border-bottom:none;
    font-weight:bold;
    font-size:16px;
}

::v-deep  .el-collapse-item__content{
    padding:0px;
}

::v-deep  .bookContent .el-collapse-item{
    margin-bottom: 15px;
}

::v-deep  .bookContent .el-collapse-item__header{
    background-color:rgb(242, 243, 245);
    border-bottom:null;
    border-radius: 3px;
    padding-left: 15px;
    font-weight:bold;
    font-size:14px;
}

::v-deep  .bookContent .el-collapse-item__wrap{
    
    border: 1px solid rgb(64, 158, 255);

    border-radius: 3px;
    margin-top: 15px;
}

::v-deep  .bookContent .el-collapse-item__content{
    padding:10px 15px;
}

.el-collapse-item__content .bookContentTitle{
    font-weight:bold;
}


::v-deep .el-collapse-item__arrow{
    display: none;
}

::v-deep .el-collapse-item__arrow.is-active {
    display: none;
  }
::v-deep .v-note-wrapper{
    min-height: 0;
    min-width: 0
}  
::v-deep .v-show-content{
    padding: 15px 25px 8px 25px;
}  
::v-deep .markdown-body{
   font-size: 14px;
}  

.tooltip-content{
    width : 500px;
    max-height: 400px;
    overflow-y: auto;
}
</style>