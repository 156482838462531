var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"aboutPage"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"usViewsF"},[_c('div',{staticClass:"viewsContent"},[_vm._m(4),_c('div',{staticClass:"content7"},_vm._l((_vm.categoryList),function(item,index){return _c('div',{key:index,staticClass:"content7Card"},[_c('div',{staticClass:"cardImage7"},[_c('img',{attrs:{"src":item.viewImage}})]),_c('div',{staticClass:"cardConAndBtn"},[_c('div',{staticClass:"cardCon"},[_vm._v(_vm._s(item.category_name))]),_c('div',{staticClass:"cardBtn"},[_c('div',{staticClass:"cardOnline",on:{"click":function($event){return _vm.consulOnline(item)}}},[_vm._v("在线咨询")]),_c('div',{staticClass:"cardGoApply",on:{"click":function($event){return _vm.goApply(item)}}},[_vm._v("立即报名")])])])])}),0)])]),_vm._m(5),_c('div',{staticClass:"usViewsC"},[_c('div',{staticClass:"viewsContent"},[_vm._m(6),_c('div',{staticClass:"content8"},[_c('div',{staticClass:"cont8Left"},[_c('div',{staticClass:"leftTitle8"},[_vm._v("消防培训咨询热线")]),_c('div',{staticClass:"leftPhone8",on:{"click":_vm.dialPhone}},[_vm._v("4001-888-119")])]),_c('div',{staticClass:"cont8Right"},[_c('div',{staticClass:"leftTitle8"},[_vm._v("微信服务号")]),_c('div',{staticClass:"rightImage",on:{"click":_vm.viewImage}},[_c('img',{attrs:{"src":"https://img.qddfxfpx.com/appimg/aboutus/code.jpg"}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usViewsF"},[_c('div',{staticClass:"viewsContent"},[_c('img',{staticClass:"logoImg",attrs:{"src":"https://img.qddfxfpx.com/appimg/aboutus/QDlogo.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usViewsC"},[_c('div',{staticClass:"viewsContent1"},[_c('div',{staticClass:"content1"},[_c('div',{staticClass:"content1Title",staticStyle:{"background":"url(https://img.qddfxfpx.com/appimg/aboutus/gather.png)","background-size":"100%"}},[_c('div',{staticClass:"content2Title2"},[_vm._v("消防人才的摇篮")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usViewsF"},[_c('div',{staticClass:"viewsContent"},[_c('div',{staticClass:"content2"},[_c('div',{staticClass:"content2Title1"},[_vm._v("科技引领消防培训数字化创新")]),_c('div',{staticClass:"content2Title2"},[_vm._v("独家研发")]),_c('div',{staticClass:"content2Title2"},[_vm._v("智能学习系统 + 实景考场")]),_c('div',{staticClass:"content2Title1"},[_vm._v("消防设施操作员国考")]),_c('div',{staticClass:"content2Title3"},[_vm._v("随时随地 省时省力")]),_c('div',{staticClass:"content2Title3"},[_vm._v("应试神器 5步通关")])]),_c('div',{staticClass:"content3"},[_c('img',{attrs:{"src":"https://img.qddfxfpx.com/appimg/aboutus/circle.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usViewsC"},[_c('div',{staticClass:"viewsContent"},[_c('div',{staticClass:"content2"},[_c('div',{staticClass:"content2Title1 topLanger"},[_vm._v("卓越教学理念：教考试+教本事")]),_c('div',{staticClass:"content2Title2"},[_vm._v("独家研发升职加薪宝")]),_c('div',{staticClass:"content2Title1 topLanger"},[_vm._v("服务消防人才终身学习和职业发展")])]),_c('div',{staticClass:"content4"},[_c('div',{staticClass:"viewsContent4Btn"},[_vm._v("卓越岗位课程")]),_c('div',{staticClass:"viewsContent4Btn"},[_vm._v("领导管理课程")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content5"},[_c('div',{staticClass:"content2Title2"},[_vm._v("培训项目"),_c('h3')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usViewsC"},[_c('div',{staticClass:"viewsContents"},[_c('div',{staticClass:"content2Title2"},[_vm._v("标杆客户"),_c('h3')]),_c('div',{staticClass:"cardimg"},[_c('img',{attrs:{"src":"https://img.qddfxfpx.com/appimg/aboutus/clientlist.jpg","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content6"},[_c('div',{staticClass:"content2Title2"},[_vm._v("联系我们"),_c('h3')])])
}]

export { render, staticRenderFns }