<template>
    <!-- <div>{{ index }} - {{ source.text }}</div> -->
    <div class="box-card" :key="index" :id="`card-${source.contentId}`"
        :class="{ 'box-card-active': source.contentId === selectId, 'box-card-add': source.contentUpdateStatus === 1, 'box-card-edit': source.contentUpdateStatus === 2, 'box-card-del': source.contentUpdateStatus === 3 }"
        @click="handleCardClick(source)">
        <div style="margin-bottom: 15px;">
            <div class="book-action" ref="title">
                <span>
                    <el-tag v-if="source.contentUpdateStatus > 0" effect="dark" size="medium" style="margin-right: 5px;"
                        :type="source.contentUpdateStatus === 1 ? 'success' : source.contentUpdateStatus === 2 ? 'warning' : source.contentUpdateStatus === 3 ? 'danger' : 'info'">{{
        source.contentUpdateStatus
            === 1 ? '增' : source.contentUpdateStatus === 2 ? '改' : source.contentUpdateStatus === 3 ? '删'
                :
                '' }}</el-tag>
                    {{ source.bookContentTitle }}
                </span>
                <span style="display: flex; align-items: center;">
                    <el-button v-if="source.isGenerateQuestion" style="margin-right: 10px; " size="small" type="primary"
                        plain @click="handleQuestion(source.bookContentKnowledgePoints)">
                        查看知识点问题
                    </el-button>
                    <el-tooltip effect="dark"
                        :content="source.contentUpdateStatus === 2 && '本级变更回修改前的状态' || source.contentUpdateStatus === 3 && '本级及子目录变更回未删除的状态' || ''"
                        placement="top">
                        <el-button v-if="source.contentUpdateStatus > 0 && source.approval !== 2" style="margin-right: 10px; " size="small"
                            class="recall-btn" plain type="info" @click="handleReCall(source)">
                            <img src="../../public/icon/path.png" alt=""> 撤销
                        </el-button>
                    </el-tooltip>
                    <el-switch v-if="menuType === 'knowledge'" v-model="source.enableStatus"
                        @change="switchChange($event, source)">
                    </el-switch>
                </span>
            </div>
            <mavon-editor
                :previewBackground="`${source.contentId === selectId ? '#ffffff' : source.contentUpdateStatus === 1 ? '#EEFFEE' : source.contentUpdateStatus === 2 ? '#FFF9F1' : source.contentUpdateStatus === 3 ? '#FFF8F8' : '#F2F3F5'}`"
                boxShadowStyle="none" v-model="source.bookContent" :editable='false' :toolbarsFlag='false'
                :subfield='false' :defaultOpen='"preview"' :id="`content-${source.contentId}`" />
            <!-- <div class="book-content"  v-html="source.bookContent">
            </div> -->
        </div>
        <div class="book-footer">
            <div class="book-pic-wrap">
                <div class="pic-keyword">
                    图片:

                    <span v-if="source.imageUrls && source.imageUrls.length > 0">
                        <img class="pic-img" v-for="(item, index) in source.imageUrls" :key="index" :src="item"
                            @click="handlePictureCardPreview(item)" />
                    </span>
                    <span v-else>暂无</span>
                </div>
            </div>
            <div class="book-keyword-wrap">
                <div class="book-keyword">
                    关键字:
                    <span v-if="source.bookContentKeyword">
                        <el-tag size="small" :effect="source.contentUpdateStatus > 0 ? 'plain' : 'light'"
                            :type="source.contentUpdateStatus === 1 ? 'success' : source.contentUpdateStatus === 2 ? 'warning' : source.contentUpdateStatus === 3 ? 'danger' : 'info'"
                            style="margin-right: 5px;margin-top: 5px;"
                            v-for="(item, index) in source.bookContentKeyword.split('|')" :key="index">{{ item
                            }}</el-tag>
                    </span>
                    <span v-else>暂无</span>
                </div>
                <i v-if="menuType === 'book' && source.contentUpdateStatus !== 3 && listApproval !== 2"
                    class="el-icon-delete" style="font-size: 18px; cursor: pointer;"
                    @click.stop="handleDelete(source)"></i>
            </div>
            <div class="book-solt-wrap">
                槽位:
                <span v-if="source.slotInfos">
                    <el-tag size="medium" effect="dark" style="margin-right: 5px;margin-top: 5px;"
                        :color="item.slotColor" :style="{ 'border-color': item.slotColor }"
                        v-for="(item, index) in source.slotInfos" :key="index">{{ item.slotName }}</el-tag>
                </span>
                <span v-else>暂无</span>
            </div>
        </div>
        <el-dialog :visible.sync="dialogPreview" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <el-dialog :visible.sync="dialogQuestion" append-to-body title="相似知识点问题"">
            <div class=" question-card" id="question-wrap" v-if="dialogQuestion">
            <mavon-editor previewBackground="#ffffff" boxShadowStyle="none" v-model="bookContentKnowledgePoints"
                :editable='false' :toolbarsFlag='false' :subfield='false' :defaultOpen='"preview"' />
    </div>
    </el-dialog>
    </div>
</template>

<script>
import { changeBookContentStatus, bookContentRollback } from "../api/api";
import Mark from 'mark.js'

export default {
    name: 'item-component',
    props: {
        index: { // index of current item
            type: Number
        },
        source: { // here is: {uid: 'unique_1', text: 'abc'}
            type: Object,
            default() {
                return {}
            }
        },
        handleCardClick: {
            type: Function,
            default: () => { }
        },
        handleDelete: {
            type: Function,
            default: () => { }
        },
        _getBookContent: {
            type: Function,
            default: () => { }
        },
        selectId: {
            type: Number
        },
        menuType: {
            type: String
        },
        knowledgeId: {
            type: Number
        },
        filterText: {
            type: String
        },
        listApproval: {
            type: Number
        }
    },
    data() {
        return {
            dialogImageUrl: '',
            dialogPreview: false,
            bookContentKnowledgePoints: '',
            dialogQuestion: false
        }
    },
    methods: {
        switchChange(val, row) {
            changeBookContentStatus({
                bookId: row.bookId,
                contentId: row.contentId,
                knowledgeId: this.knowledgeId,
                status: row.enableStatus
            }).then((res) => {
                if (res.data.success) {
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: '操作失败, 请重试',
                        type: 'error'
                    });
                    row.enableStatus = !val
                }
            }).catch((err) => {
                this.$message({
                    message: '操作失败, 请重试',
                    type: 'error'
                });
                row.enableStatus = !val
            })
        },
        handlePictureCardPreview(url) {
            this.dialogImageUrl = url;
            this.dialogPreview = true;
        },
        handleQuestion(val) {
            this.bookContentKnowledgePoints = val
            this.dialogQuestion = true;
            setTimeout(() => {
                const cardMarker = new Mark(document.getElementById(`question-wrap`))
                cardMarker.unmark()
                cardMarker.mark(
                    this.filterText,
                    {
                        className: 'text-selected',
                        element: 'span',
                        each: (element) => {
                            element.style.color = '#409EFF'
                        }
                    }
                )
            })
        },
        handleReCall(row) {
            this.$confirm('将撤销当前节点及自己掉为上次审核通过的内容，是否确定撤销?')
                .then(_ => {
                    bookContentRollback({
                        bookId: row.bookId,
                        bookContentId : row.contentId,
                    }).then((res) => {
                        if (res.data.success) {
                            this.$message({
                                message: '操作成功',
                                type: 'success'
                            });
                            this._getBookContent()
                        } else {
                            this.$message({
                                message: '操作失败, 请重试',
                                type: 'error'
                            });
                        }
                    }).catch((err) => {
                        this.$message({
                            message: '操作失败, 请重试',
                            type: 'error'
                        });
                    })
                })
                .catch(_ => { });
        }
    }
}
</script>
<style scoped>
.book-footer {
    width: 100%;
}

.book-keyword-wrap,
.book-pic-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.recall-btn {
    display: flex;
    align-items: center;
}

.recall-btn img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

.book-keyword {
    width: 93%;
}

.pic-img {
    height: 60px;
    width: 60px;
    margin-right: 10px;
    cursor: pointer;
}

.book-solt-wrap {
    margin-top: 5px;
}

.tree-box {
    width: 37%;
    margin-top: 20px;
    overflow-y: scroll;
    max-height: 500px;
}

.right-content {
    width: 65%;
    overflow-y: scroll;
    height: 500px;
    padding: 20px;
}

.box-card {
    width: 93%;
    min-height: 200px;
    /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1); */
    margin-bottom: 20px;
    padding: 14px 17px;
    background: #F2F3F5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.box-card-active {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    background: #FFF;
    border-radius: 4px;
    border: 1px solid #409EFF;
}

.question-card {
    background: #FFF;
    border-radius: 4px;
    border: 1px solid #409EFF;
    padding: 1px;
}

.book-action {
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box-card-add {
    border: 1px solid #07B700;
    background: #EEFFEE;
}

.box-card-edit {
    border: 1px solid #FFB143;
    background: #FFF9F1;
}

.box-card-del {
    border: 1px solid #FF5050;
    background: #FFF8F8;

}
</style>